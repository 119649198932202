/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <MissionEditorDialog
      v-if="activeMission != null"
      ref="missionEditorDialog"
      :item="activeMission"
      :title="'Ajout une mission '"
      @save="saveMission"
    />
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex my-1">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <c-auto-complete
                    v-if="option.url"
                    display="chip"
                    v-model="option.value"
                    chips
                    :option="option"
                    :force-selection="true"
                  />
                  <p-multi-select
                    v-else-if="option.items && option.items.length != 0"
                    v-model="option.value"
                    :options="option.items"
                    :optionLabel="option.key"
                    :placeholder="option.label"
                    display="chip"
                  />
                  <p-input-text
                    v-else
                    v-model="option.value"
                    :placeholder="option.label"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des missions</h4>
        </div>
        <div class="btn-group">
          <a @click.prevent="ajouterMission" class="ms-2 style_btn btn btn-warning">
            Ajouter une mission
          </a>
        </div>
        <PMessage
          v-show="errorMessage"
          :severity="'error'"
          @close="errorMessage = null"
          >{{ errorMessage }}</PMessage
        >
        <div class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>

                <template #empty="scope">
                  <h4>{{ scope.emptyText }}</h4>
                  <!-- Vous n'avez pas encore commencé l -->
                  <button class="btn btn-primary" @click="genererListeDePresence">
                    Génér la liste de présence
                  </button>
                </template>

                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(status)="{ item: cand }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getStatusMissionColor(cand.status)"
                    v-if="cand.status"
                    >{{ cand.status }}
                  </PTag>
                  <!-- <span class="">
                    <i
                      v-if="cand.nombreAbsenceSansMotif != null"
                      class="bi bi-check-circle text-success"
                      style="font-size: 1.5rem"
                    ></i>
                    <i
                      v-else
                      class="bi bi-slash-circle text-danger"
                      style="font-size: 1.5rem"
                    ></i>
                  </span> -->
                </template>
                <template #cell(isEvalue)="{ item: de }">
                  <span class="text-uppercase">
                    <i
                      v-if="de.isEvalue"
                      v-tooltip.left="'Evaluée'"
                      class="bi bi-check-circle text-success"
                      style="font-size: 1.5rem"
                      v-badge.success="de.typeCandidature == 'RECOMMANDE' ? 'R' : 'S'"
                    ></i>
                    <i
                      v-else
                      v-tooltip.left="'Non évaluée'"
                      class="bi bi-slash-circle text-danger"
                      style="font-size: 1.5rem"
                      v-badge.danger="de.typeCandidature == 'RECOMMANDE' ? 'R' : 'S'"
                    ></i>
                  </span>
                </template>
                <template #cell(nom)="{ item: de }">
                  <span class="text-uppercase">{{ de.nom }}</span>
                </template>
                <template #cell(dateDu)="{ item: de }">
                  <span class="text-uppercase">{{
                    $dayjs(de.dateDu).format("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #cell(dateAu)="{ item: de }">
                  <span class="text-uppercase">{{
                    $dayjs(de.dateAu).format("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #row-details="row">
                  <PTabView :activeIndex.sync="activeTab" class="mt-5">
                    <PTabPanel header="Ordres de missions">
                      <OrdreMissionList
                        :mission="row.item"
                        :extraQueryArg="`missionId=${row.item.id}`"
                      />
                    </PTabPanel>
                    <PTabPanel header="Avantages de missions">
                      <AvantageOrdreMissionList
                        :mission="row.item"
                        :extraQueryArg="`missionId=${row.item.id}`"
                      />
                    </PTabPanel>
                  </PTabView>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      v-tooltip.top="'Voir les ordres de missions'"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                    <!-- <a
                      class="btn btn-primary rounded-0 btn-sm me-1"
                      v-if="pp.datePriseService == null"
                      v-tooltip.top="'Prise de service'"
                      @click.stop="showPriseDeServiceEditorDialog(row.item)"
                    >
                      <i class="bi bi-chevron-bar-expand"></i>
                    </a> -->
                    <!-- <a
                      class="btn btn-warning rounded-0 btn-sm me-1"
                      v-tooltip.top="'Présence au poste'"
                      @click.stop="showMissionEditorDialog(row.item)"
                    >
                      <i class="bi bi-person-check"></i>
                    </a> -->
                    <!-- <a
                      class="btn btn-danger rounded-0 btn-sm me-1"
                      v-tooltip.top="'Cessation de service'"
                      @click.stop="showCessationServiceEditorDialog(row.item)"
                      ><i class="bi bi-journal-x"></i
                    ></a> -->
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "../../../../mixins/auth-mixin";
import CAutoComplete from "../../../../components/common/CAutoComplete.vue";

import MissionEditorDialog from "../../../../components/espace/common/mission/editor/MissionEditorDialog.vue";
import OrdreMissionList from "./OrdreMissionList.vue";
import AvantageOrdreMissionList from "./AvantageOrdreMissionList.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    ligneBudgetaire: {
      type: Object,
    },
  },
  components: {
    MissionEditorDialog,
    CAutoComplete,
    OrdreMissionList,
    AvantageOrdreMissionList,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      selected: [],
      search: "",
      activePriseDeService: {},
      activeCessationService: {},
      activeMission: {},
      participationPlacement: null,
      dateDeb: null,
      dateFin: null,
      errorMessage: null,
      showAdvancedFilter: false,
      nonEvalueesCountLoading: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      placementValidation: {},
      currentOffre: {},
      activeTransmission: {},
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      activeRecommandation: {
        demandeur: [],
      },
      parAeEnregistres: [],
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: "mission/exportToPdf",
                queryParams: params,
                fileName: "Liste des présences au poste.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: "mission/exportToExcel",
                queryParams: params,
                fileName: "Liste des présences au poste.pdf",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["beneficiaire"],
      searchFieldsOptions: [{ label: "Bénéficiaire", value: "beneficiaire" }],
      filterOptions: [
        {
          column: "beneficiaire",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          column: "creePar",
          value: "",
          label: "AE (Nom & prénom)",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          column: "departement",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          column: "commune",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          url: "/communes",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          column: "arrondissement",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/arrondissements",
          label: "Arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          column: "ville",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier de ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          column: "status",
          key: "label",
          value: "",
          label: "Statut du dossier",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "NOUVEAU", label: "Sans dossier" },
            { value: "EN_COURS", label: "En cours" },
            { value: "TERMINE", label: "Terminé" },
            { value: "VALIDE", label: "Validé" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "metierAppris",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier appris",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          column: "metierDesire",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier recherché",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeCandidature: {},
      activeCandidature: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    emptyPlacementVal() {
      return {
        posteOccupe: null,
        observation: null,
        typePlacement: null,
        natureContrat: null,
        dateDebut: null,
        dateFin: null,
      };
    },
    emptyPlacementVal() {
      return {
        posteOccupe: null,
        observation: null,
        typePlacement: null,
        natureContrat: null,
        dateDebut: null,
        dateFin: null,
      };
    },
    tableFields() {
      return [
        "#",
        { key: "reference", label: "Ref" },
        { key: "objetMission", label: "Nom" },
        { key: "dateDu", label: "Date Début" },
        // { key: "participationPlacement.beneficiaire", label: "Prénom" },
        { key: "dateAu", label: "Date Fin" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    // currentOffre() {
    //   const offre = this.mxFilteredItems[0] != null? this.mxFilteredItems[0].offre : {}
    //   return offre
    // }
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchDemandeurs: "demandeur/fetchDemandeurs",
      fetchOffre: "offre/getOneOffre",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      downloadAzoli: "demandeur/downloadAzoli",
      fetchDossier: "demandeur/fetchDossier",
      validerDossier: "demandeur/validerDossier",
      transfererVersConseiller: "transfert/transfererVersConseiller",
      accepterPropostionCandidature: "candidature/accepterPropostionCandidature",
      exigerPropostionCandidature: "candidature/exigerPropostionCandidature",
      transmettrePropositionCandidatures:
        "candidature/transmettrePropositionCandidatures",
      retirerRecommanation: "candidature/retirerRecommanationCandidature",
      fetchCandidatureNonEvaluesCount: "offre/fetchCandidatureNonEvaluesCount",
      createOrUpdatePriseService: "priseService/createOrUpdatePriseService",
      createOrUpdateArretContrat: "arretContrat/createOrUpdateArretContrat",
      createOrUpdateMission: "mission/createOrUpdateMission",
      generateListeDePresence: "mission/genererListeDePresence",
      recommanderProfils: "offre/recommanderProfils",
    }),
    genererListeDePresence() {
      this.generateListeDePresence({
        ligneBudgetaire: this.ligneBudgetaire,
      }).then((data) => {
        this.queryStatistics();
      });
    },
    ajouterMission() {
      this.showMissionEditorDialog({});
    },
    showMissionEditorDialog(mission) {
      this.activeMission = { ...mission };
      this.$refs.missionEditorDialog.show();
    },
    showPriseDeServiceEditorDialog({ participationPlacement }) {
      // this.activeParticipationPlacement = { ...participationPlacement }
      this.activePriseDeService = { participationPlacement };
      this.$refs.priseDeServiceEditorDialog.show();
    },
    showCessationServiceEditorDialog(participationPlacement) {
      this.activeCessationService = { participationPlacement };
      this.$refs.cessationServiceEditorDialog.show();
    },
    savePriseDeService(priseDeService) {
      this.createOrUpdatePriseService(priseDeService)
        .then((data) => {
          this.$toast.success("Prise de service sauvegardée avec succès!", {
            position: "top-right",
            duration: 8000,
          });
          this.queryStatistics();
        })
        .catch((err) => err);
    },
    saveMission(mission) {
      if (this.ligneBudgetaire != null) {
        mission.ligneBudgetaire = this.ligneBudgetaire;
      }
      this.createOrUpdateMission(mission)
        .then((data) => {
          this.$toast.success("Présence au poste sauvegardée avec succès!", {
            position: "top-right",
            duration: 8000,
          });
        })
        .catch((err) => err);
    },
    saveCessationService(cessationService) {
      this.createOrUpdateArretContrat(cessationService)
        .then((data) => {
          this.$toast.success("Cessation de service sauvegardée avec succès!", {
            position: "top-right",
            duration: 8000,
          });
        })
        .catch((err) => err);
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, demandeurId: de.id },
      });
    },
    showRecommandationCandidature() {
      this.activeRecommandation = {
        offreId: this.offreId,
        demandeurs: [],
      };
      this.$refs.demandeurSelector.show();
    },
    transfertDemandeur(agent) {
      console.log("Agent: ", agent);
      if (this.selected.length == 0) {
        this.$toast.error(
          "Aucun demandeur sélectionné. Passez en mode tableau puis sélectionnez le(s) demandeur(s) avant de continuer. ",
          {
            position: "top-right",
            duration: 8000,
          }
        );
        return;
      }
      this.transfererVersConseiller({
        agent,
        demandeur: this.selected[0],
      }).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
      });
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    getStatusMissionColor(status) {
      let color = "primary";
      if (status == "CONTROLE") {
        color = "success";
      } else if (status == "CAS_IRREGULIER") {
        color = "danger";
      }

      return color;
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "mission",
        search: this.search,
        fetcherMethod: "mission/fetchMissions",
        dataGetter: "mission/missions",
        paginationGetter: "mission/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 20,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          return `${this.extraQueryArg}`;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    editDemandeur(demandeur) {
      this.activeCandidature = { ...demandeur };
      this.$router.push({ name: "espace.conseiller.de.edit" });
    },
    savePlacementValidation(placementValidation) {
      if (placementValidation && placementValidation.candidature) {
        console.log(placementValidation);
        if (placementValidation.candidature.status == "RECALE") {
          this.exigerPropostionCandidature({
            candidatureId: placementValidation.candidature.id,
            placementValidation,
          }).then((data) => {
            this.queryStatistics();
          });
        } else if (placementValidation.candidature.status == "PROPOSE") {
          this.accepterPropostionCandidature({
            candidatureId: placementValidation.candidature.id,
            placementValidation,
          }).then((data) => {
            this.queryStatistics();
          });
        }
      }
    },
    accepterCandidature(candidature) {
      this.placementValidation = {
        ...this.emptyPlacementVal,
        candidature,
      };
      this.$refs.missionEditorDialog.show();
    },
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
